import * as React from 'react';
import Layout from '../components/layout';
import Container from '../components/container';

export default function NotFoundPage() {
  return (
    <Layout>
      <Container className="max-w-3xl space-y-10 text-center">
        <h1 className="mt-20 font-bold text-center text-gray-800 text-8xl">404</h1>

        <div className="max-w-3xl space-y-4 text-xl text-gray-700">
          <p>You must be lost</p>
          <p className="max-w-md mx-auto">
            Looks like you’ve followed a broken link or entered a URL that doesn’t exist on our
            website.
          </p>
          <a href="/" className="block text-indigo-600">
            Start from the beginning
          </a>
        </div>
      </Container>
    </Layout>
  );
}
